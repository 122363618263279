import { ref } from '@vue/composition-api'

export default function useFilter() {
  const searchQuery = ref(null)

  const defaultStatus = ['pending', 'confirmed', 'wait_for_amendment']

  const statusOptions = [
    { text: 'Pending', value: 'pending' },
    { text: 'Confirmed', value: 'confirmed' },
    { text: 'Wait For Amendment', value: 'wait_for_amendment' },
  ]

  return {
    searchQuery,
    defaultStatus,
    statusOptions,
  }
}
