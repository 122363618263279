<template>
  <v-card-text class="d-flex align-center flex-wrap pb-0">
    <v-spacer></v-spacer>

    <div class="d-flex align-center pb-5">
      <v-text-field
        v-model="searchQuery"
        single-line
        dense
        outlined
        hide-details
        placeholder="Appointment ID,  User Account email@, phone(>=6 digits)"
        style="width: 500px"
        class="mr-2"
        @input="onSearch"
      ></v-text-field>

      <v-select
        v-model="selectedStatuses"
        :items="statusOptions"
        outlined
        dense
        hide-details
        multiple
        placeholder="Select Status"
        :menu-props="{ maxHeight: '400' }"
        @change="onStatusChanged"
      ></v-select>
    </div>
  </v-card-text>
</template>

<script>
import { ref } from '@vue/composition-api'
import debounce from 'lodash/debounce'

import useFilter from '../../composables/appointment/useFilter'

export default {
  setup(props, { emit }) {
    const { searchQuery, defaultStatus, statusOptions } = useFilter()

    const selectedStatuses = ref(defaultStatus)

    const onSearch = debounce(() => {
      emit('search', searchQuery.value)
    }, 1000)

    const onStatusChanged = () => {
      emit('statusChanged', selectedStatuses.value)
    }

    return {
      searchQuery,
      selectedStatuses,
      statusOptions,

      onSearch,
      onStatusChanged,
    }
  },
}
</script>
../../composables/appointment/useFilter
