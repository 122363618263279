<template>
  <v-form
    ref="formElem"
    @submit.prevent="() => {}"
  >
    <!-- Dialog Content -->
    <v-card>
      <v-card-title>Confirm Appointment</v-card-title>
      <v-card-text>
        <p><b>Appointment Number:</b> {{ appointment.current_number }} </p>

        <p v-if="participant">
          <b>Participant:</b> {{ participant.last_name }} {{ participant.first_name }}
        </p>

        <p v-if="participant">
          <b>Contact:</b> {{ participant.phone }}, {{ participant.email }}
        </p>

        <p><b>Service:</b> {{ appointment.rechargeable.name.en }} </p>
        <p><b>Location:</b>{{ t(destination.name || destination.address) }}</p>
        <p v-if="confirmedApplications.length">
          <b>Previously Confirmed: </b>
          <template
            v-for="app in confirmedApplications"
            v-if="app.inventory_snapshot && app.inventory_snapshot.info"
          >
            {{ app.inventory_snapshot.info.started_at | date }}
          </template>
        </p>
        <p><b>Selected Date & Time:</b></p>
        <v-radio-group
          v-model="selectedAppId"
        >
          <v-radio
            v-for="app in confirmableApplications"
            v-if="app.inventory_snapshot && app.inventory_snapshot.info"
            :key="app.id"
            :label="app.inventory_snapshot.info.started_at | date"
            :value="app.id"
          />
        </v-radio-group>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          type="button"
          class="mr-4"
          color="success"
          :loading="loading"
          :disabled="loading"
          @click="confirmAppointment"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { appointmentService } from '@/modules/product/services'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'
import moment from 'moment'

export default {
  filters: {
    date: value => {
      if (!value) return

      return moment(value).format('YYYY-MM-DD HH:mm')
    },
  },
  props: {
    appointment: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const formElem = ref(null)
    const selectedAppId = ref(null)

    const toggleDialog = status => {
      isDialogVisible.value = status
    }

    const confirmableApplications = computed(() => props.appointment.applications.filter(application => application.status === 'pending'))

    const confirmedApplications = computed(() => props.appointment.applications.filter(application => application.status === 'accepted'))

    const confirmAppointment = async () => {
      if (!formElem.value.validate()) return

      loading.value = true

      try {
        await appointmentService.confirm(selectedAppId.value)

        toggleDialog(false)
        reset()

        emit('confirmed')
      } catch (err) {
        console.log(err)

        alert('Something went wrong!')
      }
    }

    const reset = () => {
      loading.value = false
      selectedAppId.value = null
    }

    const destination = computed(() => props.appointment.applications?.[0]?.destination)

    const participant = computed(() => props.appointment.group?.participant)

    return {
      t,
      loading,
      isDialogVisible,
      destination,
      participant,

      toggleDialog,

      selectedAppId,
      confirmableApplications,
      confirmedApplications,
      confirmAppointment,

      formElem,
      required,
    }
  },
}
</script>
