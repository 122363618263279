<template>
  <v-card>
    <ListFilter
      @statusChanged="onStatusChanged"
      @search="onSearching"
    />
    <ListTable
      :appointments="appointments"
      :total-appointments="totalAppointments"
      :loading="loading"
      @refresh="fetchAppointments"
      @optionChanged="onOptionChanged"
    />
  </v-card>
</template>

<script>
import { useTableOptions } from '@/composables'
import { userQuotaService } from '@/modules/product/services'
import { ref } from '@vue/composition-api'
import ListFilter from '../../components/appointment/ListFilter.vue'
import ListTable from '../../components/appointment/ListTable.vue'
import useFilter from '../../composables/appointment/useFilter'
import useTable from '../../composables/appointment/useTable'

export default {
  components: {
    ListFilter,
    ListTable,
  },
  setup() {
    const loading = ref(false)

    const { defaultStatus } = useFilter()
    const { tableOptions } = useTable()

    const queryParams = ref({
      status_in: defaultStatus,
    })

    const appointments = ref([])
    const totalAppointments = ref(0)

    const fetchAppointments = async () => {
      loading.value = true

      const { records } = await userQuotaService.index(useTableOptions(tableOptions.value, queryParams.value))

      appointments.value = records.filter(record => !['available', 'depleted', 'expired'].includes(record.status))
      totalAppointments.value = appointments.value.length

      loading.value = false
    }

    const onOptionChanged = options => {
      tableOptions.value = options

      fetchAppointments()
    }

    const onSearching = searchQuery => {
      queryParams.value.search = searchQuery

      fetchAppointments()
    }

    const onStatusChanged = status => {
      queryParams.value.status_in = status

      fetchAppointments()
    }

    return {
      loading,

      defaultStatus,

      appointments,
      totalAppointments,

      onSearching,
      onStatusChanged,
      onOptionChanged,

      fetchAppointments,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
