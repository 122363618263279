import { ref } from '@vue/composition-api'

export default function useTable() {
  const tableColumns = [
    { text: 'ID', value: 'current_number' },
    { text: 'Participant', value: 'participant', sortable: false },
    { text: 'Contact', value: 'participant_contact', sortable: false },
    { text: 'Account', value: 'user_id' },
    { text: 'Location', value: 'destination_id' },
    { text: 'Service', value: 'rechargeable_id' },
    { text: 'Service Datetime', value: 'applications', sortable: false },
    { text: 'Status', value: 'status' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableOptions = ref({
    itemsPerPage: 15,
    page: 1,
    sortBy: ['current_number'],
    sortDesc: [true],
  })

  return {
    tableColumns,
    tableOptions,
  }
}
